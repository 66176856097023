import Crm from './Crm'
export default class CCSS {
  id!: number
  member_number!: Crm
  start_date!: Date
  insurance_number!: number
  monthly_quota!: number
  status!: number
  tariff_type!: number
  account_balance!: number
  reported_salary!: number
  automatic_charge_applies!: number
  dependent!: number
  inputMemberNumber!: string
  next_payment_date!: Date
  associate_member!: number
}
